//import Home from './components/Home';
// import Layout from './components/Layout';
// import Project from './components/Project';
// import Skill from './components/Skill';
// import Contact from './components/Contact';
// import Blog from './components/Blog';
import React, {lazy} from 'react';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'
// import ArticleDetail from './components/ArticleDetail';

const Layout = lazy(() => import('./components/Layout'))
const Home = lazy(() => import('./components/Home'))
const Project = lazy(() => import('./components/Project'))
const Skill = lazy(() => import('./components/Skill'))
const Contact = lazy(() => import('./components/Contact'))
const Blog = lazy(() => import('./components/Blog'))
const ArticleDetail = lazy(() => import('./components/ArticleDetail'))
const Gallery = lazy(() => import('./components/Gallery'))


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="project" element={<Project />} />
          <Route path="skill" element={<Skill />} />
          <Route path="contact" element={<Contact />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<ArticleDetail />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
